// src/components/Home.js
import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const Home = () => {
  // Access user data from UserContext
  const { user, logout } = useContext(UserContext);

  useEffect(() => {
    document.title = "Plateforme E-learning"; // Set the document title
  }, []);

  return (
    <main>
      <h1>Cours de français</h1>

      {/* Conditionally render links based on the existence of user data */}
      {user ? (
        <>
          {(user.type === "formateur" || user.typer ==="admin") && <p><Link to="/mes-cours">Mes cours</Link></p>}
          <p><button onClick={logout} style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Déconnexion</button></p>
        </>
      ) : (
        <>
          <p><Link to="/connexion">Connexion</Link></p>
          <p><Link to="/inscription">Inscription</Link></p>
        </>
      )}

      <p><Link to="/cours">Tous les cours</Link></p>

      <h2>CM1</h2>
      <Link to="/CODLesson">Le complément d’objet direct</Link>

      <h2>Sixième</h2>
    </main>
  );
};

export default Home;
