import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home'
import CODLesson from './components/CODLesson';
import CourseDetails from './components/CourseDetails';
import Login from './components/Login';
import SignUp from './components/SignUp';
import CreateCourse from './components/CreateCourse';
import LessonDetails from './components/LessonDetails';
import { UserProvider } from './contexts/UserContext';
import CreateLesson from './components/CreateLesson';
import CourseList from './components/CourseList';
import CreateExercice from './components/CreateExercice';


function App() {
  return (
    <div className="App">
      <UserProvider>
        {/* Utilisation du composant Router pour gérer la navigation */}

        <Router>
          {/* Définition des routes et de leurs composants correspondants. Par exemple, la route (path="/CODLesson") affiche le composant CODLesson lorsque l'URL correspond à "/CODLesson". */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/connexion" element={<Login />} />
            <Route path="/inscription" element={<SignUp />} />
            <Route path="/cours/:id_cours" element={<CourseDetails />} />
            <Route path="/cours/:id_cours/lecon/:id_lesson" element={<LessonDetails />} />
            <Route path="/cours/:id_cours/ajouterlecon/" element={<CreateLesson />} />
            <Route path="/cours/:id_cours/lecon/:id_lesson/ajouterexercice" element={<CreateExercice />} />
            <Route path="/CODLesson" element={<CODLesson />} />
            <Route path="/mes-cours" element={<CourseList forUser={true} />} />
            <Route path="/cours" element={<CourseList />} />
            <Route path="/creer-cours" element={<CreateCourse />} />
          </Routes>
        </Router>
      </UserProvider>
    </div>
  );
}

export default App;
