import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../contexts/UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import config from '../config';

const CreateLesson = () => {
  const { user } = useContext(UserContext);
  const { id_cours } = useParams();
  const [titre, setTitre] = useState('');
  const [contenu, setContenu] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    document.title = "Ajout de leçon";
    if (!user || !token) {
      navigate('/connexion'); // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
      return;
    }
  }, [user, token, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!titre.trim()) {
      return alert('Le titre de la leçon ne doit pas être vide.');
    }
    if (!contenu.trim()) {
      return alert('Le contenu de la leçon ne doit pas être vide.');
    }

    try {
      const coursResponse = await fetch(`${config.apiBaseUrl}/courses/${id_cours}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!coursResponse.ok) {
        throw new Error('Failed to fetch course details');
      }

      const cours = await coursResponse.json();

      // Vérification des droits de l'utilisateur
      if (cours.id_formateur !== user.id_utilisateur && user.type !== 'admin') {
        setError('Vous n\'êtes pas autorisé à ajouter une leçon.');
        return;
      }

      const response = await fetch(`${config.apiBaseUrl}/lessons/${id_cours}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ titre, contenu })
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.message || 'Une erreur s\'est produite lors de l\'ajout de la leçon';
        throw new Error(errorMessage);
      }

      alert('La leçon a été ajoutée avec succès.');
      navigate(`/cours/${id_cours}`);
    } catch (error) {
      alert(`Erreur: ${error.message}`);
      setError(error.message);
    }
  };

  return (
    <div className="container my-5">
      <h1 className="mb-4">Créer une nouvelle leçon</h1>
      {error && <div className="alert alert-danger" role="alert">{error}</div>}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Titre"
          type="text"
          value={titre}
          onChange={(e) => setTitre(e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="Contenu"
          type="text"
          value={contenu}
          onChange={(e) => setContenu(e.target.value)}
          fullWidth
          required
          multiline
          rows={4}
        />
        <Button type="submit" variant="contained" color="primary" className="mt-3">
          Soumettre
        </Button>
      </form>
    </div>
  );
};

export default CreateLesson;
