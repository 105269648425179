// src/components/CODExercise.js
import React, { useState } from 'react';

const CODExercise = () => {
    // État pour stocker les réponses de l'utilisateur pour chaque question. Il est initialisé avec 10 éléments null.
    const [answers, setAnswers] = useState(Array(10).fill(null));
    // État pour indiquer si le formulaire a été soumis.  Il est initialement défini sur false.
    const [submitted, setSubmitted] = useState(false);

    // Tableau des réponses correctes
    const correctAnswers = [
        "une maison",
        "les mains",
        "la voiture",
        "des gâteaux",
        "les",
        "des fraises",
        "que tu reviennes",
        "sa jupe",
        "des nems",
        "toute la population",
    ];

    /* Fonction pour mettre à jour les réponses de l'utilisateur.  Cette fonction est appelée chaque fois qu'un utilisateur sélectionne une réponse à une question. Elle met à jour l'état du tableau answers avec la nouvelle réponse de l'utilisateur.*/
    const handleAnswerChange = (index, choice) => {
        const newAnswers = [...answers];
        newAnswers[index] = choice;
        setAnswers(newAnswers);
    };

    /* Fonction pour gérer la soumission du formulaire. Cette fonction est appelée lors de la soumission du formulaire. Elle empêche le comportement par défaut du formulaire et met à jour l'état submitted à true.*/
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
    };

    /*Cette fonction génère le contenu des résultats à afficher une fois que le formulaire a été soumis. Elle parcourt le tableau answers et le tableau correctAnswers pour comparer les réponses de l'utilisateur aux réponses correctes et affiche les résultats pour chaque question.*/
    const renderResults = () => {
        /*La méthode map() crée un nouveau tableau avec les résultats de l'appel d'une fonction fournie sur chaque élément du tableau appelant.*/
        return answers.map((answer, index) => (
            /*Chaque élément <div> créé à l'aide de map() doit avoir une clé unique pour que React puisse les différencier.*/
            <div key={index}>
                <p>
                    <strong>Question {index + 1}:</strong>
                    <br />
                    <span>
                        <strong>Réponse correcte :</strong> {correctAnswers[index]}
                        <br />
                        <strong>Votre réponse :</strong> {answer}
                    </span>
                </p>
            </div>
        ));
    };

    // Fonction pour calculer le score
    const calculateScore = () => {
        let score = 0;
        answers.forEach((answer, index) => {
            if (answer === correctAnswers[index]) {
                score++;
            }
        });
        return score;
    };

    /* Vérifier si toutes les questions ont été répondues. La méthode every parcourt chaque élément du tableau answers et renvoie true si la fonction de test fournie renvoie true pour chaque élément du tableau. En l'occurrence, la fonction de test vérifie si chaque réponse n'est pas null. Si toutes les réponses ne sont pas null, cela signifie que toutes les questions ont été répondues.*/
    const allQuestionsAnswered = answers.every(answer => answer !== null);


    /*Le composant rend un formulaire avec plusieurs ensembles de questions. Chaque ensemble de questions est représenté par un fieldset avec une légende indiquant la phrase à analyser et des options de réponse sous forme de boutons radio. Les boutons radio sont désactivés une fois que le formulaire est soumis (submitted est true).*/
    return (
        <div>
            <h3>Trouvez le COD dans chaque phrase.</h3>
            <form className="exercice" onSubmit={handleSubmit}>
                <fieldset>
                    <legend>Pierre et Marie ont acheté une maison au bord de la mer.</legend>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q1"
                            value="au bord de la mer"
                            onChange={() => handleAnswerChange(0, "au bord de la mer")}
                        />
                        au bord de la mer
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q1"
                            value="Pierre et Marie"
                            onChange={() => handleAnswerChange(0, "Pierre et Marie")}
                        />
                        Pierre et Marie
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q1"
                            value="une maison"
                            onChange={() => handleAnswerChange(0, "une maison")}
                        />
                        une maison
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q1"
                            value="Je ne sais pas"
                            onChange={() => handleAnswerChange(0, "Je ne sais pas")}
                        />
                        Je ne sais pas
                    </label>
                </fieldset>

                <fieldset>
                    <legend>Les enfants se lavent les mains dans la salle de bains.</legend>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q2"
                            value="les mains"
                            onChange={() => handleAnswerChange(1, "les mains")}
                        />
                        les mains
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q2"
                            value="les enfants"
                            onChange={() => handleAnswerChange(1, "les enfants")}
                        />
                        les enfants
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q2"
                            value="dans la salle de bain"
                            onChange={() => handleAnswerChange(1, "dans la salle de bain")}
                        />
                        dans la salle de bain
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q2"
                            value="Je ne sais pas"
                            onChange={() => handleAnswerChange(1, "Je ne sais pas")}
                        />
                        Je ne sais pas
                    </label>
                </fieldset>

                <fieldset>
                    <legend>Monsieur Dupont gare la voiture dans son garage.</legend>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q3"
                            value="Monsieur Dupont"
                            onChange={() => handleAnswerChange(2, "Monsieur Dupont")}
                        />
                        Monsieur Dupont
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q3"
                            value="dans son garage"
                            onChange={() => handleAnswerChange(2, "dans son garage")}
                        />
                        dans son garage
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q3"
                            value="la voiture"
                            onChange={() => handleAnswerChange(2, "la voiture")}
                        />
                        la voiture
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q3"
                            value="Je ne sais pas"
                            onChange={() => handleAnswerChange(2, "Je ne sais pas")}
                        />
                        Je ne sais pas
                    </label>
                </fieldset>

                <fieldset>
                    <legend>Max mange des gâteaux avec sa petite sœur.</legend>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q4"
                            value="Max"
                            onChange={() => handleAnswerChange(3, "Max")}
                        />
                        Max
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q4"
                            value="des gâteaux"
                            onChange={() => handleAnswerChange(3, "des gâteaux")}
                        />
                        des gâteaux
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q4"
                            value="avec sa petite sœur"
                            onChange={() => handleAnswerChange(3, "avec sa petite sœur")}
                        />
                        avec sa petite sœur
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q4"
                            value="Je ne sais pas"
                            onChange={() => handleAnswerChange(3, "Je ne sais pas")}
                        />
                        Je ne sais pas
                    </label>
                </fieldset>

                <fieldset>
                    <legend>Maman les a punis car ils ont chahuté dans la chambre.</legend>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q5"
                            value="dans la chambre"
                            onChange={() => handleAnswerChange(4, "dans la chambre")}
                        />
                        dans la chambre
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q5"
                            value="les"
                            onChange={() => handleAnswerChange(4, "les")}
                        />
                        les
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q5"
                            value="maman"
                            onChange={() => handleAnswerChange(4, "maman")}
                        />
                        maman
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q5"
                            value="Je ne sais pas"
                            onChange={() => handleAnswerChange(4, "Je ne sais pas")}
                        />
                        Je ne sais pas
                    </label>
                </fieldset>

                <fieldset>
                    <legend>Ce matin, Lise a acheté des fraises au marché.</legend>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q6"
                            value="des fraises"
                            onChange={() => handleAnswerChange(5, "des fraises")}
                        />
                        des fraises
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q6"
                            value="ce matin"
                            onChange={() => handleAnswerChange(5, "ce matin")}
                        />
                        ce matin
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q6"
                            value="au marché"
                            onChange={() => handleAnswerChange(5, "au marché")}
                        />
                        au marché
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q6"
                            value="Je ne sais pas"
                            onChange={() => handleAnswerChange(5, "Je ne sais pas")}
                        />
                        Je ne sais pas
                    </label>
                </fieldset>

                <fieldset>
                    <legend>Mélissa veut que tu reviennes vite à la maison.</legend>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q7"
                            value="que tu reviennes"
                            onChange={() => handleAnswerChange(6, "que tu reviennes")}
                        />
                        que tu reviennes
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q7"
                            value="Mélissa"
                            onChange={() => handleAnswerChange(6, "Mélissa")}
                        />
                        Mélissa
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q7"
                            value="à la maison"
                            onChange={() => handleAnswerChange(6, "à la maison")}
                        />
                        à la maison
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q7"
                            value="Je ne sais pas"
                            onChange={() => handleAnswerChange(6, "Je ne sais pas")}
                        />
                        Je ne sais pas
                    </label>
                </fieldset>

                <fieldset>
                    <legend>Jenny a rangé sa jupe dans le placard.</legend>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q8"
                            value="sa jupe"
                            onChange={() => handleAnswerChange(7, "sa jupe")}
                        />
                        sa jupe
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q8"
                            value="dans le placard"
                            onChange={() => handleAnswerChange(7, "dans le placard")}
                        />
                        dans le placard
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q8"
                            value="Jenny"
                            onChange={() => handleAnswerChange(7, "Jenny")}
                        />
                        Jenny
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q8"
                            value="Je ne sais pas"
                            onChange={() => handleAnswerChange(7, "Je ne sais pas")}
                        />
                        Je ne sais pas
                    </label>
                </fieldset>

                <fieldset>
                    <legend>Paul a goûté des nems au restaurant asiatique.</legend>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q9"
                            value="des nems"
                            onChange={() => handleAnswerChange(8, "des nems")}
                        />
                        des nems
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q9"
                            value="au restaurant asiatique"
                            onChange={() => handleAnswerChange(8, "au restaurant asiatique")}
                        />
                        au restaurant asiatique
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q9"
                            value="Paul"
                            onChange={() => handleAnswerChange(8, "Paul")}
                        />
                        Paul
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q9"
                            value="Je ne sais pas"
                            onChange={() => handleAnswerChange(8, "Je ne sais pas")}
                        />
                        Je ne sais pas
                    </label>
                </fieldset>

                <fieldset>
                    <legend>La tempête a surpris toute la population à cause de sa violence.</legend>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q10"
                            value="à cause de sa violence"
                            onChange={() => handleAnswerChange(9, "à cause de sa violence")}
                        />
                        à cause de sa violence
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q10"
                            value="la tempête"
                            onChange={() => handleAnswerChange(9, "la tempête")}
                        />
                        la tempête
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q10"
                            value="toute la population"
                            onChange={() => handleAnswerChange(9, "toute la population")}
                        />
                        toute la population
                    </label>
                    <label>
                        <input
                            type="radio"
                            disabled={submitted}
                            name="q10"
                            value="Je ne sais pas"
                            onChange={() => handleAnswerChange(9, "Je ne sais pas")}
                        />
                        Je ne sais pas
                    </label>
                </fieldset>

                <button type="submit" disabled={submitted || !allQuestionsAnswered}>
                    {/* Condition ternaire pour déterminer le libellé du bouton */}
                    {allQuestionsAnswered ? (submitted ? "Voir résultats ci-dessous" : "Valider") : 'Veuillez répondre à toutes les questions.'}
                </button>
            </form>

            {/*Affiche les résultats si submitted est true*/}
            {submitted && (
                <div>
                    <h2>Résultats</h2>
                    {renderResults()}
                    <p>Score total: {calculateScore()} / 10</p>
                </div>
            )}
        </div>
    );
};

export default CODExercise;
