// src/components/Login.js
import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import config from '../config'; // Import the configuration

const Login = () => {
  const [email, setEmail] = useState('');
  const [mot_de_passe, setMotDePasse] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(UserContext);

  useEffect(() => {
    document.title = "Connexion"; // Change the document title when the component mounts
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${config.apiBaseUrl}/users/login`, { // Use the base URL from config
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, mot_de_passe }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.message || 'An error occurred during login';
        throw new Error(errorMessage);
      }

      const data = await response.json();
      login(data); // Update context and localStorage
      alert(`Login successful, ${data.user.prenom}`);
      navigate('/');
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <h1>Connexion</h1>
      <form className="FormControl" onSubmit={handleSubmit}>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="Mot de passe"
          type="password"
          value={mot_de_passe}
          onChange={(e) => setMotDePasse(e.target.value)}
          fullWidth
          required
        />
        <Button type="submit" variant="contained" color="primary">
          Envoyer
        </Button>
      </form>
    </div>
  );
};

export default Login;
