// src/components/CODLesson.js
import React, { useState } from 'react';
import { TextField, RadioGroup, Radio, FormControlLabel, Select, MenuItem, Button, FormControl, FormLabel, InputLabel } from '@mui/material';

const Form = () => {
     // Déclaration des états pour les champs de formulaire et les données soumises
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [genre, setGenre] = useState('');
    const [age, setAge] = useState('');
    const [submittedData, setSubmittedData] = useState(null); // État pour stocker les données soumises

    // Fonction pour gérer la soumission du formulaire
    const handleSubmit = (event) => {
        event.preventDefault(); // Empêche le rechargement de la page lors de la soumission du formulaire 
        const data = { nom, prenom, genre, age }; // Crée un objet avec les données du formulaire
        setSubmittedData(data); // Met à jour l'état des données soumises avec les données du formulaire
    };

    return (
        <div>
            <h2>Formulaire de présentation</h2>
            <form className='presentationForm' onSubmit={handleSubmit}>
                {/* Champ de saisie pour le nom */}
                <TextField
                    label="Nom" //Etiquette du formulaire
                    value={nom} //valeur actuelle de l'élément de formulaire.
                    onChange={(e) => setNom(e.target.value)} // Met à jour l'état du nom à chaque changement dans le champ de saisie
                    autoComplete="family-name" //permet d'ajouter l'attribut autocomplete au formulaire avec la valeur défini. Attention, le C est majuscule ici
                    fullWidth //pour spécifier que l'élément doit occuper toute la largeur disponible de son conteneur parent. W masjuscule pour Witdth.
                    required //Indique que le champ est obligatoire
                />
                {/* Champ de saisie pour le prénom */}
                <TextField
                    label="Prénom"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                    fullWidth
                    autoComplete="given-name" 
                    required
                />
                {/* Groupe de boutons radio pour sélectionner le genre */}
                <FormControl component="fieldset" fullWidth required>
                    <FormLabel component="legend">Genre</FormLabel>
                    <RadioGroup
                        aria-label="Genre"
                        name="genre"
                        value={genre}
                        onChange={(e) => setGenre(e.target.value)} //Met à jour l'état du genre à chaque sélection du bouton radio
                    >
                        <FormControlLabel value="Masculin" control={<Radio />} label="Masculin" />
                        <FormControlLabel value="Féminin" control={<Radio />} label="Féminin" />
                        {/*<FormControlLabel value="Autre" control={<Radio />} label="Autre" />*/}
                    </RadioGroup>
                </FormControl>
                <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">Age</InputLabel>
                    <Select
                        value={age}
                        labelId="demo-simple-select-label" //spécifier l'ID HTML de l'étiquette associée à l'élément de formulaire. I majuscule pour Id.
                        id="demo-simple-select"
                        onChange={(e) => setAge(e.target.value)}
                        fullWidth
                        label="Age"
                    >
                        {/* Boucle pour créer les options du sélecteur d'âge. Array(9) crée un tableau vide de longueur 9.  
                        .keys() renvoie un tableau produisant les indices des éléments du tableau, de 0 à 8 dans ce cas.*/}
                        {[...Array(9).keys()].map((index) => (
                            <MenuItem key={index + 7} value={index + 7}>{index + 7}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button type="submit" variant="contained" color="primary">
                    Valider
                </Button>
            </form>

           {/* Affichage des données soumises si elles existent */}
            {submittedData && (
                <div role='alert' aria-live='polite'>
                    <h3>
                        Informations saisies
                    </h3>
                    <p>
                        Nom: {submittedData.nom}
                    </p>
                    <p>
                        Prénom: {submittedData.prenom}
                    </p>
                    <p>
                        Genre: {submittedData.genre}
                    </p>
                    <p>
                        Age: {submittedData.age}
                    </p>
                </div>
            )}
        </div>
    );
};

export default Form;